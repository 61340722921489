@charset 'UTF-8';

label {
  cursor: pointer;
}

.donation-form {
  display: block;
  margin-top: calc(var(--gutter) / 2);
}

.donation-form legend {
  font-family: var(--font-family-quicksand-bold);
  font-size: var(--font-size-h6-fluid);
  line-height: var(--type-scale-major-second);
  margin-bottom: calc(var(--gutter) / 4);
}

.donation-frequency-and-donation-amounts {
  --gap: var(--gutter);
  --vertical-padding: calc(var(--gutter) / 2);

  align-items: center;
  border-color: var(--color-neon-rose-100);
  border-style: solid;
  border-width: 0 0 1px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: calc(var(--gap) / 4) var(--gap);
  margin-bottom: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);
  place-content: center flex-start;
  width: 100%;
}

.donation-frequency,
.donation-amounts {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}

.donation-frequency {
  flex-grow: 0;
  flex-shrink: 0;
}

.donation-amounts::before {
  --on-surface-color: var(--color-neon-rose-700);
  --arrow-size: 10px;
  --color: transparent;
  --reset: 0;

  border-bottom: var(--arrow-size) solid var(--color);
  border-left: var(--arrow-size) solid var(--on-surface-color);
  border-right: var(--reset) solid var(--color);
  border-top: var(--arrow-size) solid var(--color);
  content: "";
  height: var(--reset);
  left: calc(var(--arrow-size) * 2 * -1);
  margin-top: 0;
  position: absolute;
  top: calc(var(--arrow-size) - 4px * 1);
  width: var(--reset);
}

/* ----------------------------------------------- */
.donation-form-options {
  --font-size: var(--font-size-body-fluid);

  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: var(--font-family-quicksand-bold);
  font-size: var(--font-size);
  gap: calc(var(--gutter) / 4);
  line-height: var(--font-size-body);
  list-style: none;
  margin: 0;
  padding: 0;
  place-content: center flex-start;
}

.donation-form-options li {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.donation-form-options li.donation-amount-option-other {
  flex-grow: 1;
  flex-shrink: 1;
}

.donation-form-options
  li.donation-amount-option-other[data-donation-amount-is-compatible-with-donation-frequency="false"] {
  opacity: 0.45;
  pointer-events: none;
}

.donation-frequency-options {
  -moz-column-gap: 0;
       column-gap: 0;
  font-size: var(--font-size-body-small);
}

.donation-form-options li.donate-frequency-label {
  padding-right: calc(var(--gutter) / 4);
}

.donation-form-options li.donation-frequency-option {
  --padding: 2px;

  background-color: var(--color-neon-rose-200);
  padding-bottom: var(--padding);
  padding-top: var(--padding);
}

.donation-frequency-option-one-time {
  padding-left: var(--padding);
}

.donation-frequency-option-recurring {
  padding-right: var(--padding);
}

.donation-amount-option label {
  transition-duration: var(--speed-fast), var(--speed-normal), var(--speed-fast);
  transition-property: background-color, box-shadow, color;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-color-or-opacity),
    var(--easing-color-or-opacity);
}

/* ----------------------------------------------- */
.donation-form-options label,
.donation-form-options input[type="text"],
.donation-form-options input[type="number"] {
  --padding: calc(var(--gutter) / 2);

  align-items: center;
  background-color: var(--color-neon-rose-050);

  /* border-radius: var(--rounded-corners-small); */
  color: var(--color-neon-rose-700);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-bottom: calc(var(--padding) / 2);
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-top: calc(var(--padding) / 2);
  place-content: center center;
  text-shadow: var(--text-shadow-white);
}

/* ----------------------------------------------- */
.donation-form-options .donation-frequency-option label,
.donation-form-options .donation-frequency-option label.is-checked::before {
  --check-mark-width: 6px;
}

.donation-form-options .donation-frequency-option label {
  --horizontal-padding: calc(var(--gutter) / 2 + var(--check-mark-width) * 2);

  background-color: var(--color-white);
  color: var(--color-neon-rose-700);
  padding-left: var(--horizontal-padding);
  padding-right: var(--horizontal-padding);
  position: relative;
}

/* ----------------------------------------------- */
.donation-form-options .donation-amount-option-other label {
  background-color: var(--color-neon-rose-100);
  color: var(--color-neon-rose-800);
}

/* ----------------------------------------------- */
.donation-form-options .donation-frequency-option label.is-checked,
.donation-form-options .donation-amount-option label.is-checked {
  background-color: var(--color-neon-rose-500);
  color: var(--color-white);
  pointer-events: none;
  text-shadow: var(--text-shadow-neon-rose);
  transition-property: none;
}

.donation-form-options .donation-amount-option label:hover,
.donation-form-options .donation-amount-option label:active,
.donation-form-options .donation-amount-option label:focus {
  background-color: var(--color-neon-rose-500);

  /* box-shadow: var(--shadow-elevation-medium); */
  color: var(--color-white);
  text-shadow: none;
  transition-duration: var(--speed-extra-fast), var(--speed-normal), var(--speed-extra-fast);
}

.donation-form-options .donation-amount-option label:active,
.donation-form-options .donation-amount-option label:focus {
  box-shadow: none;
  transition-property: none;
}

.donation-form-options .donation-frequency-option label.is-checked::before {
  --stroke-weight: 2px;

  /* --check-mark-height: 12px; */
  --check-mark-height: calc(var(--check-mark-width) * 2);

  background-color: transparent;
  border-bottom: var(--stroke-weight) solid var(--color-white);
  border-right: var(--stroke-weight) solid var(--color-white);
  content: "";
  height: var(--check-mark-height);
  left: calc(var(--check-mark-width) * 2);
  position: absolute;
  top: calc(50% - var(--check-mark-height) / 2 - 1px);
  transform: rotate(45deg);
  width: var(--check-mark-width);
}

/* ----------------------------------------------- */
.donation-form-options input[type="radio"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none;
  position: absolute;
  visibility: hidden;
  z-index: -1;
}

/* ----------------------------------------------- */
.donation-form-options input[type="text"],
.donation-form-options input[type="number"] {
  background-color: var(--color-neon-rose-050);
  border: none;
  outline: none;
  padding-bottom: calc(var(--gutter) / 3);
  padding-top: calc(var(--gutter) / 3);
  width: 100%;
}

.donation-form-options input[type="text"]::-moz-placeholder, .donation-form-options input[type="number"]::-moz-placeholder {
  background-color: var(--color-neon-rose-050);
  color: var(--color-neon-rose-900);
}

.donation-form-options input[type="text"]::placeholder,
.donation-form-options input[type="number"]::placeholder {
  background-color: var(--color-neon-rose-050);
  color: var(--color-neon-rose-900);
}

/* ----------------------------------------------- */
.donation-form-submit-and-confirmation-display {
  align-items: stretch;
  -moz-column-gap: calc(var(--gutter) / 2);
       column-gap: calc(var(--gutter) / 2);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  place-content: stretch flex-start;
}

.donation-form-submit-and-confirmation-display-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

/* ----------------------------------------------- */
.donation-frequency-and-amount-confirmation {
  --vertical-margin: 0;

  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: calc(var(--rounded-corners-medium) / 2);
  place-content: stretch flex-start;
  row-gap: calc(var(--gutter) / 4);
}

.donation-frequency-and-amount-confirmation dt {
  display: none;
}

.donation-frequency-and-amount-confirmation dd {
  --font-size: var(--font-size-body);

  font-size: var(--font-size);
  line-height: var(--font-size);
  margin-left: 0;
}

.donation-frequency-and-amount-confirmation dd.donation-frequency {
  font-family: var(--font-family-quicksand-semi-bold);
}

.donation-frequency-and-amount-confirmation dd.donation-amount {
  color: var(--color-neon-rose-700);
  font-family: var(--font-family-quicksand-semi-bold);
  font-size: var(--font-size-h6-fluid);
}

/* ----------------------------------------------- */
button[type="submit"] {
  --font-size: var(--font-size-h6-fluid);
  --gap: calc(var(--gutter) / 2);
  --surface-color: var(--color-neon-rose-500);
  --on-surface-color: var(--color-white);

  align-items: center;
  background-color: var(--surface-color);
  background-repeat: no-repeat;
  border-radius: var(--rounded-corners-medium);
  box-shadow: var(--shadow-elevation-medium);
  color: var(--on-surface-color);
  -moz-column-gap: var(--gap);
       column-gap: var(--gap);
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-quicksand-bold);
  font-size: var(--font-size);
  line-height: var(--font-size);
  margin-left: auto;
  margin-right: auto;
  /* stylelint-disable-next-line declaration-no-important */
  outline: none !important;
  overflow: hidden;
  padding: var(--gap);
  place-content: center center;
  position: relative;
  text-align: center;
  text-shadow: var(--text-shadow-neon-rose);
  transition-duration: var(--speed-normal), var(--speed-normal);
  transition-property: background-color, box-shadow;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-color-or-opacity);
  white-space: nowrap;
}

button[data-is-disabled="true"] {
  pointer-events: none;
}

button[type="submit"]:hover,
button[type="submit"]:active,
button[type="submit"]:focus {
  text-shadow: none;
}

button[type="submit"]:hover {
  --surface-color: var(--color-neon-rose-500);

  box-shadow: var(--shadow-elevation-high);
  transition-duration: var(--speed-extra-fast), var(--speed-extra-fast);
}

button[type="submit"]:active,
button[type="submit"]:focus {
  --surface-color: var(--color-neon-rose-400);

  box-shadow: none;
  transition-property: none;
}

/* ----------------------------------------------- */
.donation-form-fine-print {
  border-top: 2px solid var(--color-neon-rose-500);
  font-size: var(--font-size-body-extra-small);
  margin-top: var(--gutter);
  padding-top: calc(var(--gutter) / 4);
}

@charset 'UTF-8';

.alert,
.alert div,
.alert-icon {
  --icon-size: 20px;
}

.alert,
.alert div {
  --gap: calc(var(--gutter) / 4);
}

.alert {
  --surface-color: var(--color-white);
  --on-surface-color: var(--color-black);
  --font-size: var(--font-size-body-small);
  --position-x: var(--gutter);
  --position-y: var(--gutter);
  --min-width: 200px;
  --max-width: 414px;
  --preferred-width: 100%;

  align-items: center;
  background-color: var(--surface-color);
  border-radius: var(--rounded-corners-small);
  box-shadow: var(--shadow-elevation-medium);
  color: var(--on-surface-color);
  -moz-column-gap: var(--gap);
       column-gap: var(--gap);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-quicksand-bold);
  font-size: var(--font-size);
  line-height: var(--font-size);
  place-content: flex-start flex-start;
  pointer-events: none;
  position: fixed;
  right: var(--position-x);
  top: var(--position-y);
  transform: translateX(calc(100% + var(--gutter)));
  visibility: hidden;
  width: clamp(var(--min-width), var(--preferred-width), var(--max-width));
  z-index: 1000;
}

.alert[data-alert-is-visible="true"] {
  pointer-events: all;
  transform: translateX(0);
  transition-duration: var(--speed-extra-fast);
  transition-property: transform;
  transition-timing-function: var(--easing-entering);
  visibility: visible;
}

.alert div {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;

  /* order: 0; */
  position: relative;
}

.alert div.icon,
.alert div.action {
  --padding: calc(var(--icon-size) / 2);

  flex-basis: var(--icon-size);
  flex-grow: 0;
  padding: var(--padding);
}

.alert div.action {
  --padding: calc(var(--icon-size) / 4);

  align-self: flex-start;
}

.alert div.message {
  padding-bottom: var(--gap);
  padding-top: var(--gap);
}

/* ----------------------------------------------- */
.alert-icon {
  --surface-color: var(--color-midnight-blue-900);
  --on-surface-color: var(--color-white);

  align-items: center;
  background-color: var(--surface-color);
  border-radius: 100%;
  color: var(--on-surface-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: var(--icon-size);
  place-content: center center;
  width: var(--icon-size);
}

.alert-icon svg {
  fill: currentcolor;
  stroke-width: 0;
}

/* ----------------------------------------------- */
.alert-dismiss {
  /* stylelint-disable-next-line declaration-no-important */
  --surface-color: transparent !important;
  --on-surface-color: var(--color-midnight-blue-900);
}

/* ----------------------------------------------- */
.alert.success {
  --surface-color: var(--color-moss-green-600);
  --on-surface-color: var(--color-white);

  text-shadow: var(--text-shadow-moss-green);
}

.alert.success .alert-icon {
  --surface-color: var(--color-moss-green-950);
}

.alert.success .alert-dismiss {
  --on-surface-color: var(--color-moss-green-1000);
}

@charset 'UTF-8';

.giving-works-badge,
.giving-works-badge dd {
  --giving-works-logo-width: 140px;
}

.giving-works-badge {
  align-items: center;
  border-top: 2px solid var(--color-neon-rose-500);
  -moz-column-gap: var(--gutter);
       column-gap: var(--gutter);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: var(--font-size-body-extra-small);
  line-height: var(--type-scale-golden-ratio);
  margin-top: var(--gutter);
  padding-top: calc(var(--gutter) / 4);
  place-content: flex-start flex-start;
}

.giving-works-badge dt {
  display: none;
}

.giving-works-badge dd {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
}

.giving-works-badge dd.giving-works-logo {
  flex-basis: var(--giving-works-logo-width);
  flex-grow: 0;
  flex-shrink: 0;
}

.giving-works-badge dd.giving-works-logo img {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--giving-works-logo-width);
}

/* ----------------------------------------------- */

@media (width <= 540px) {
  .giving-works-badge {
    flex-wrap: wrap;
  }

  .giving-works-badge dd.giving-works-logo,
  .giving-works-badge dd.giving-works-statement {
    flex-basis: 100%;
    text-align: center;
  }

  .giving-works-badge dd.giving-works-logo {
    padding-bottom: calc(var(--gutter) / 4);
  }
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/3fd1467a3a24a9e6-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/e5a448ebd52a1afd-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/1218ffa1d5ea28b1-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/dae9007f4b44df69-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/753ab63508c1917e-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/8626d83920fcb0af-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/86a64d30a633c3c8-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/989dd8fcb6c7087b-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/45b5a0869bad146c-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/4fb94b917be0ff7f-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/331d1e0b1512bdaa-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/ea663d828d15df29-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: 'customFont Fallback';src: local("Arial");ascent-override: 96.26%;descent-override: 24.07%;line-gap-override: 0.00%;size-adjust: 103.88%
}.__className_d968b4 {font-family: 'customFont', 'customFont Fallback', Helvetica, ui-sans-serif
}

