@charset 'UTF-8';

.giving-works-badge,
.giving-works-badge dd {
  --giving-works-logo-width: 140px;
}

.giving-works-badge {
  align-items: center;
  border-top: 2px solid var(--color-neon-rose-500);
  column-gap: var(--gutter);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: var(--font-size-body-extra-small);
  line-height: var(--type-scale-golden-ratio);
  margin-top: var(--gutter);
  padding-top: calc(var(--gutter) / 4);
  place-content: flex-start flex-start;
}

.giving-works-badge dt {
  display: none;
}

.giving-works-badge dd {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
}

.giving-works-badge dd.giving-works-logo {
  flex-basis: var(--giving-works-logo-width);
  flex-grow: 0;
  flex-shrink: 0;
}

.giving-works-badge dd.giving-works-logo img {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--giving-works-logo-width);
}

/* ----------------------------------------------- */

@media (width <= 540px) {
  .giving-works-badge {
    flex-wrap: wrap;
  }

  .giving-works-badge dd.giving-works-logo,
  .giving-works-badge dd.giving-works-statement {
    flex-basis: 100%;
    text-align: center;
  }

  .giving-works-badge dd.giving-works-logo {
    padding-bottom: calc(var(--gutter) / 4);
  }
}
